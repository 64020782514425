import PropTypes from "prop-types";
import React from "react";
import Header from "./header";
import Footer from "./footer";

function Layout({ hero, title, subtitle, children, location }) {
  return (
    <div className="flex flex-col font-sans min-h-screen text-gray-900">
      <Header
        hero={hero}
        pathname={location?.pathname}
        subtitle={subtitle}
        title={title}
      />
      <main>
        {children}
        <Footer></Footer>
      </main>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
