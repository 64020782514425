import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CaretDown from "../../static/img/caret-down.svg";
import CaretDownWhite from "../../static/img/caret-down-white.svg";

const NavButton = styled.button`
  &:focus {
    outline: 0;
  }
  &:hover span {
    border-color: #37a576;
    border-bottom-width: 1px;
    color: #37a576;
  }
`;

function NavSection(props) {
  const [expanded, setExpanded] = useState(false);
  const ref = useRef();
  useEffect(() => {
    const closeIfClickedOutside = (e) => {
      if (expanded && ref.current && !ref.current.contains(e.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener(`mousedown`, closeIfClickedOutside);

    return () => {
      document.removeEventListener(`mousedown`, closeIfClickedOutside);
    };
  }, [expanded]);
  return (
    <>
      <NavButton
        className={`bloom-text-xl bloom-px-4 bloom-py-2 
        ${props.isLight ? "bloom-text-white" : "bloom-text-black-full"} 
        ${props.active ? `bloom-font-bold` : ``}`}
        id={props.id}
        onClick={() => setExpanded(!expanded)}
      >
        <div className="bloom-flex bloom-items-center">
          <span>{props.title}</span>
          {props.isLight ? (
            <img className="bloom-ml-2 bloom-mt-1" src={CaretDownWhite} />
          ) : (
            <img className="bloom-ml-2 bloom-mt-1" src={CaretDown} />
          )}
        </div>
      </NavButton>
      <div
        aria-labelledby={props.id}
        className={`${
          expanded ? `bloom-absolute` : `bloom-hidden`
        } bloom-ml-3 bloom-flex bloom-flex-col bloom-bg-white`}
        ref={ref}
      >
        {props.children}
      </div>
    </>
  );
}

export default NavSection;
