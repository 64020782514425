import React from "react";
import { Link } from "gatsby";

function NavLink(props) {
  return props.external ? (
    <a
      className={`bloom-flex bloom-nav-link bloom-text-l bloom-px-4 bloom-py-2 ${props.styles}`}
      href={props.to}
    >
      <span>{props.children}</span>
    </a>
  ) : (
    <Link
      className={`bloom-flex bloom-nav-link bloom-text-l bloom-px-4 bloom-py-2 ${props.styles}`}
      {...props}
      activeClassName={`bloom-font-bold`}
    >
      <span>{props.children}</span>
    </Link>
  );
}

export default NavLink;
