import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import BloomGreen from "../../static/img/bloom-green.svg";
import SBACert from "../../static/img/sba-cert.png";
import LinkedIn from "../../static/img/linkedin.png";
import Medium from "../../static/img/medium.png";

function Footer() {
  let year = new Date().getFullYear();

  return (
    <div className={`bloom-bg-grey-darkest bloom-py-4`}>
      <div
        className={`bloom-container bloom-mx-auto bloom-flex bloom-items-center auto-cols-max`}
      >
        <img
          className={`bloom-h-12 bloom-mr-6`}
          role={`presentation`}
          src={BloomGreen}
        />
        <img
          className={`bloom-h-12 bloom-mr-6`}
          role={`presentation`}
          src={SBACert}
        />
        <span className={`bloom-text-white bloom-flex-grow object-center`}>
          &copy; {year} Bloom Works
        </span>
        <a href="https://www.linkedin.com/company/govbloom/">
          <img
            className={`bloom-h-5 bloom-mr-6`}
            role={`presentation`}
            src={LinkedIn}
          />
        </a>
        <a href="https://medium.com/pollinator">
          <img
            className={`bloom-h-8 bloom-mr-6`}
            role={`presentation`}
            src={Medium}
          />
        </a>
      </div>
    </div>
  );
}

export default Footer;
